import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { NavLink } from "./NavbarElements";

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleClickOutside = useCallback((event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeMenu();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleLinkClick = () => {
    closeMenu();
  };

  return (
    <div ref={menuRef} className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
      <button className="dropdown-toggle" onClick={toggleMenu}>
        Fundos
      </button>
      <CSSTransition
        in={isOpen}
        timeout={3000}
        classNames="dropdown-animation"
      >
        <ul className="dropdown-list">
          <NavLink to="/panorama" className='DropdownBack' style={{ color: 'black', margin: '10px 0' }} onClick={handleLinkClick}>Fundo Panorama</NavLink>
          <NavLink to="/orion" className='DropdownBack' style={{ color: 'black', margin: '10px 0' }} onClick={handleLinkClick}>Fundo Orion</NavLink>
          <NavLink to="/alpha" className='DropdownBack' style={{ color: 'black', margin: '10px 0' }} onClick={handleLinkClick}>FIC FIDC Polígono Alpha</NavLink>
          <NavLink to="/bravo" className='DropdownBack' style={{ color: 'black', margin: '10px 0' }} onClick={handleLinkClick}>FIC FIDC Polígono Bravo</NavLink>
          <NavLink to="/marketplace" className='DropdownBack' style={{ color: 'black', margin: '10px 0' }} onClick={handleLinkClick}>FIC FIDC PLGN Marketplace</NavLink>
          <NavLink to="/alpha-btg" className='DropdownBack' style={{ color: 'black', margin: '10px 0' }} onClick={handleLinkClick}>FIM CP PLGN Alpha BTG Prev</NavLink>
        </ul>
      </CSSTransition>
    </div>
  );
};

export default DropdownMenu;
