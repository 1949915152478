import React from "react";
import './fundos.css';

import RegulamentoBravo from '../assets/docs/Polígono Bravo CP MULTI FIC FIDC.pdf';

const Bravo = () => {
  return (

    <div id="main-container">
      <div id="secao-comunicados">
        <h1 className="text-3xl font-bold text-center">FIC FIDC Polígono Bravo</h1>
        <h2 className="text-3xl font-bold text-center">Comunicados</h2>

        {/* Conteúdo da seção centralizada à esquerda */}
        <div id="container-comunicados">
          <div>
            <p><strong className="text-2xl">04 de abril de 2024</strong></p>
            <br />
            <a
              href={RegulamentoBravo}
              download
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Regulamento FIDC Crédito Estruturado
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Bravo;