import React, { useState } from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import Logo from "../../assets/img/logo-white.png";
import DropdownMenu from "./DropdownMenu";
import { Link } from "react-scroll";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Nav>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "0px",
          }}
        >
          <img
            src={Logo}
            alt="Polígono Capital"
            style={{ height: "50px", marginTop: "1rem" }}
          />
          <Bars onClick={toggleMenu} />
        </div>

        <NavMenu className={`NavMenu ${isOpen ? "open" : ""}`} style={{ marginLeft: "auto" }}>
          <NavLink to="/">Home</NavLink>
          <DropdownMenu />
          <NavBtn>
            <NavBtnLink>
              <Link
                activeClass="active"
                to="contato"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                // onClick={toggleMenu}
              >
                Contato
              </Link>
            </NavBtnLink>
          </NavBtn>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
