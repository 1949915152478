import React from "react";
import "./AppTW.css";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Panorama from "./pages/Panorama";
import Orion from "./pages/Orion";
import Alpha from "./pages/Alpha";
import Bravo from "./pages/Bravo";
import MarketPlace from "./pages/Marketplace";
import AlphaBTG from "./pages/AlphaBTG";

function App() {
  return (
    
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflowX: "hidden",
      }}
    >
      
      <Router>
      <Navbar />
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/panorama" element={<Panorama />} />
            <Route path="/orion" element={<Orion />} />
            <Route path="/alpha" element={<Alpha />} />
            <Route path="/bravo" element={<Bravo />} />
            <Route path="/marketplace" element={<MarketPlace />} />
            <Route path="/alpha-btg" element={<AlphaBTG />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
