import React from 'react';
import '../AppTW.css';
import RegulamentoAlphaBTG from '../assets/docs/DOC_REGUL_154279_152745_2024_06_AlphaBTG.pdf';

const Alpha = () => {
  return (
    <div id="main-container">
      <div id="secao-comunicados">
        <h1 className="text-3xl font-bold text-center">FIM CP POLÍGONO ALPHA BTG PREV.</h1>
        <h2 className="text-3xl font-bold text-center">Comunicados</h2>
        <div id="container-comunicados">
          <div>
            <p><strong className="text-2xl">26 de junho de 2024</strong></p>
            <br />
            <a
              href={RegulamentoAlphaBTG}
              download
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Regulamento FIM CP Polígono Alpha BTG Prev.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alpha;
