import React from "react";
import "../../AppTW.css";
import LogoPoligono from "../../assets/img/logo-white.webp";
import LogoAnbima from "../../assets/img/anbima.webp";
import PoliticaPrivacidade from "../../assets/docs/Politica-Publica-de-Privacidade-rev.-jan.-2024.pdf";
import PoliticaGestaoRisco from "../../assets/docs/Politica-de-Gestao-de-Risco-rev.-jan.-2024.pdf";
import PoliticaGerenciamentoLiquidez from "../../assets/docs/Politica-de-Gerenciamento-de-Liquidez-Poligono rev. mai. 2024.pdf";
import PoliticaExercicioDireitoVoto from "../../assets/docs/Politica-de-Exercicio-de-Direito-de-Voto-rev.-jan.-2024.pdf";
import PoliticaCertificacaoColaboradores from "../../assets/docs/Política de Certificação de Colaboradores rev. jan. 2024.pdf";
import ManualCompliance from "../../assets/docs/Manual-de-Compliance-rev.-mai.-2024.pdf";
import CodigoEtica from "../../assets/docs/Codigo-de-Etica-e-Conduta-rev.-jan.-2024.pdf";
import PoliticaRateioOrdens from "../../assets/docs/Politica-de-Rateio-de-Ordens-rev.-jan.-2024.pdf";
import PoliticaNegociacaoValoresMobiliarios from "../../assets/docs/Politica-de-Negociacao-de-Valores-Mobiliarios-rev.-jan.-2024.pdf";
import FormularioReferencia from "../../assets/docs/Poligono-Capital-Formulario-de-Referencia_ano-base_2023_v.-final.pdf";

const Footer = () => {
  return (
    <footer className="bg-custom-black text-white p-8">
      <div className="max-w-screen-lg mx-auto">
        <div>
          {/* div de cima */}
          <div className="flex flex-col md:flex-row justify-between items-start mb-8">
            <div className="mb-4 md:mb-0">
              <img src={LogoPoligono} className="w-32" alt="Polígono Capital" />
            </div>
            <div id="contato" className="flex-1">
              <div
                id="detalheContatos"
                className="flex flex-col md:flex-row justify-center items-start md:space-x-16"
              >
                <div
                  id="contatoPoligono"
                  className="md:flex-1 text-left md:text-left"
                >
                  <h3 className="font-semibold">Polígono Capital Ltda.</h3>
                  <ul className="list-none p-0">
                    <li>
                      <a
                        href="tel:+551150439354"
                        className="text-gray-300 hover:text-white"
                      >
                        +55 (11) 5043-9354
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:contato@poligono.com"
                        className="text-gray-300 hover:text-white"
                      >
                        contato@poligono.com
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  id="contatoInjectiva"
                  className="md:flex-1 text-left md:text-left"
                >
                  <h3 className="font-semibold">Injectiva Ltda. (Agente de Cobrança)</h3>
                  <ul className="list-none p-0">
                    <li>
                      <a
                        href="tel:+5511941506180"
                        className="text-gray-300 hover:text-white"
                      >
                        +55 (11) 94150-6180
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:cobranca@injectiva.com.br"
                        className="text-gray-300 hover:text-white"
                      >
                        cobranca@injectiva.com.br
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mb-4 md:mb-0">
              <h3 className="font-semibold">Endereço</h3>
              <p className="text-gray-300 text-left">
                Av. Brigadeiro Faria Lima
                <br />
                2.601 - 12º andar
                <br />
                Jardim Paulistano
                <br />
                São Paulo - SP, 01452-000
              </p>
            </div>
          </div>

          {/* div de baixo */}
          <div className="flex flex-col md:flex-row justify-between items-start">
            <div className="mb-4 md:mb-0 md:mt-1 order-3 md:order-none">
              <a
                href="https://www.anbima.com.br/pt_br/institucional/institucional.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LogoAnbima} className="w-32" alt="Anbima" />
              </a>
            </div>
            <div className="text-left mb-4 md:mb-0 md:w-1/3 md:mr-16 md:ml-8 order-2 md:order-none">
              <p className="text-xs text-gray-300">
                <small>
                  As informações contidas nesse material são de caráter
                  exclusivamente informativo. Fundos de investimento não contam
                  com garantia do administrador do fundo, do gestor da carteira,
                  de qualquer mecanismo de seguro ou, ainda, do fundo garantidor
                  de crédito – fgc ao investidor. É recomendada a leitura
                  cuidadosa do regulamento do fundo de investimento ao aplicar
                  seus recursos. A rentabilidade obtida no passado não
                  representa garantia de rentabilidade futura e a rentabilidade
                  divulgada não é liquida de impostos. Não há garantia de que
                  este fundo terá o tratamento tributário para fundos de longo
                  prazo. Os fundos de crédito privado estão sujeitos a risco de
                  perda substancial de seu patrimônio líquido em caso de eventos
                  que acarretem o não pagamento dos ativos integrantes de sua
                  carteira, inclusive por força de intervenção, regime de
                  administração temporária, falência, recuperação judicial ou
                  extrajudicial dos emissores responsáveis pelos ativos do
                  fundo.
                </small>
              </p>
            </div>
            <div className="flex-1 md:flex md:space-x-16">
              <div className="text-left mb-4 md:mb-0 md:w-1/2 order-4 md:order-none">
                <ul className="list-none p-0">
                  <li>
                    <a
                      href={PoliticaPrivacidade}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Política de Privacidade
                    </a>
                  </li>
                  <li>
                    <a
                      href={PoliticaGestaoRisco}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Política de Gestão de Riscos
                    </a>
                  </li>
                  <li>
                    <a
                      href={PoliticaGerenciamentoLiquidez}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Política de Gerenciamento de Liquidez
                    </a>
                  </li>
                  <li>
                    <a
                      href={PoliticaExercicioDireitoVoto}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Política de Exercício de Direito de Voto
                    </a>
                  </li>
                  <li>
                    <a
                      href={PoliticaCertificacaoColaboradores}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Política de Certificação dos Colaboradores
                    </a>
                  </li>
                  <li>
                    <a
                      href={ManualCompliance}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Manual de Compliance
                    </a>
                  </li>
                </ul>
              </div>

              <div className="text-left mb-4 md:mb-0 md:w-1/2 order-5 md:order-none">
                <ul className="list-none p-0">
                  <li>
                    <a
                      href={CodigoEtica}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Código de Ética
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.contatoseguro.com.br/pt/poligonocapital/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Canal de Denúncias
                    </a>
                  </li>
                  <li>
                    <a
                      href={PoliticaRateioOrdens}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Política de Rateio de Ordens
                    </a>
                  </li>
                  <li>
                    <a
                      href={PoliticaNegociacaoValoresMobiliarios}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Política de Negociação de Valores Mobiliários
                    </a>
                  </li>
                  <li>
                    <a
                      href={FormularioReferencia}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      Formulário de Referência
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 mb-8 text-xs justify-center text-gray-300">
            <br></br>
            <br></br>
            <p>
              <small>
                Injectiva Ltda. (CNPJ 47.261.082/0001-91), sociedade do grupo
                Polígono, não é instituição financeira, mas é devidamente
                certificada e atua como prestadora de serviços de correspondente
                bancário para instituição financeira Mova Sociedade de
                Empréstimo entre Pessoas S.A. (CNPJ 33.959.738/0001-30)
                (“Mova”), nos termos da Resolução CMN 4.935/21. Para maiores
                informações sobre a Mova, incluindo os canais de contato e a
                ouvidoria da Mova, acesse:
                <a
                  href="https://mova.vc/"
                  className="text-blue-600"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <u>https://mova.vc</u>
                </a>
              </small>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
