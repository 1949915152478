import React from "react";
import '../AppTW.css'

const Home = () => {
  return (
    <div className="flex flex-col bg-white">
      <div className="hidden 2xl:block 2xl:space-y-6"><br /><br /></div>
      <div className="flex flex-col md:flex-row flex-grow items-center justify-center max-w-screen-lg mx-auto p-8 space-y-4 md:space-y-5 md:space-x-8">
        <div className="flex-1 flex justify-center md:justify-end">
          <h1 className="font-bold text-2xl md:text-3xl">
            Bem-vindo à Polígono Capital.
          </h1>
        </div>
        <div className="flex-1 flex justify-center md:justify-start">
          <p className="text-lg md:text-xl max-w-md">
            A Polígono Capital é uma gestora de fundos de investimento fundada por
            BTG Pactual Asset Management e Prisma Capital, constituída para atuar
            no mercado de credit as a service no Brasil. Oferecemos soluções
            customizadas de crédito para o mercado brasileiro, atendendo as
            necessidades de nossos clientes de forma personalizada, rápida e
            eficiente.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
